import React from 'react';
import { computed, observable, action, makeObservable } from 'mobx';
import { observer } from 'src/utils/mobx-react';
import {
    TPWrapper,
    AmountInputDescription,
    TopUpLimitsInput,
} from 'src/domains/players/webview/components/Account/limitsTab/rollingNetDepositLimit/RollingNetDepositLimit.style';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { UsersState } from 'src/domains/players/state/UsersState';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { Amount } from 'src_common/common/amount/Amount';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { DateTime } from 'src_common/utils/time/time';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';
import { getRollingLimits } from 'src_server/trpc/types/responsibleGambling';
import { useCommon } from 'src/domains/common/Common';
import { validateAmountNotRequired } from 'src/domains/players/webview/components/ValidatorsNew';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { assertNever } from 'src_common/common/assertNever';

export class RollingNetDepositLimitItemState {
    @observable public refAmountInput: HTMLInputElement | null;

    public limitAmount: FormInputState<string, Amount | undefined>;

    public constructor(
        private readonly configComponents: ConfigComponents,
        public readonly usersState: UsersState,
        private readonly clearInfoMessage: () => void,
        public readonly getLimits: () => getRollingLimits.LimitsObjectSchemaType | undefined,
        private readonly amountUsed: () => number
    ) {
        makeObservable(this);
        this.refAmountInput = null;

        this.limitAmount = FormInputState.new('').map(validateAmountNotRequired);
    }

    public setAmountInputRef = (node: HTMLInputElement | null): void => {
        this.refAmountInput = node;
    };

    public onBlur = (): void => {
        const amount = parseFloat(this.limitAmount.value);
        if (isNaN(amount) === true || amount === 0) {
            return this.limitAmount.setValue('');
        }
        return this.limitAmount.setValue(amount.toFixed(2));
    };

    @action public onChangeInput = (event: React.SyntheticEvent<HTMLInputElement>): void => {
        const newValue = event.currentTarget.value;
        this.clearInfoMessage();
        this.limitAmount.setValue(newValue);
    };

    @action public resetValue = (): void => {
        this.limitAmount.reset();
    };

    @computed private get inputDepositLimitType(): number {
        return this.getLimits()?.activeLimitValue ?? 0;
    }

    @computed public get activeLimit(): string {
        const activeValue = this.getLimits()?.activeLimitValue;
        return activeValue === undefined
            ? 'Not set'
            : this.configComponents.precision
                  .newFromOld(activeValue)
                  .format(this.usersState.currency, { withoutCurrencySymbol: true });
    }

    @computed public get amountUsedCurrency(): string {
        const alreadyUsed = this.amountUsed();

        return this.configComponents.precision.newFromOld(alreadyUsed).format(this.usersState.currency);
    }

    @computed public get amountRemaining(): string {
        const alreadyUsed = this.amountUsed();

        return this.configComponents.precision
            .newFromOld(this.inputDepositLimitType - alreadyUsed)
            .format(this.usersState.currency);
    }

    @computed public get errorMaxLimitExceeded(): boolean {
        const limitValueMax = this.getLimits()?.limitValueMax ?? null;
        const currentValue =
            this.limitAmount.result.value.type === 'ok' ? this.limitAmount.result.value.data?.multiply(100) : undefined;
        return limitValueMax !== null && limitValueMax < parseInt(currentValue?.value ?? '0');
    }

    @computed public get isError(): boolean {
        return this.limitAmount.result.value.type === 'error' || this.errorMaxLimitExceeded;
    }

    public getFormattedDate = (type: 'daily' | 'weekly' | 'monthly'): string => {
        const today = new Date();
        let targetDate = new Date();

        switch (type) {
            case 'daily': {
                //tomorrow
                targetDate.setDate(today.getDate() + 1);
                break;
            }
            case 'weekly': {
                // nextMonday
                const dayOfWeek = today.getDay();
                const daysUntilMonday = (8 - dayOfWeek) % 7;
                const correctedDaysUntilMonday = daysUntilMonday === 0 ? 7 : daysUntilMonday;

                targetDate.setDate(today.getDate() + correctedDaysUntilMonday);
                break;
            }
            case 'monthly': {
                // firstOfNextMonth
                targetDate = new Date(Date.UTC(today.getFullYear(), today.getMonth() + 1, 1));
                break;
            }
        }

        return Intl.DateTimeFormat('pl-PL', {
            timeZone: 'Europe/London',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        }).format(targetDate);
    };
}

const pendingMessage = (
    configComponents: ConfigComponents,
    usersState: UsersState,
    amount: number,
    lastUpdateDate: string
): JSX.Element | undefined => {
    const until = DateTime.from(lastUpdateDate)?.addDays(1).format('HH:mm DD-MM-YYYY');

    if (amount === 0 || until === undefined) {
        return undefined;
    }

    const next = configComponents.precision.newFromAnything(amount).format(usersState.currency);

    return (
        <I18n
            langKey='account.rolling-net-deposit-limits.pending-new-limit'
            defaultText='New limit of {next} will be available at {until}'
            params={{ next, until }}
        />
    );
};

const renderPending = (
    configComponents: ConfigComponents,
    usersState: UsersState,
    limits: getRollingLimits.LimitsObjectSchemaType | undefined
): React.ReactElement | null => {
    const pendingLimitValue = limits?.pendingLimitValue;
    const pendingRequestedAt = limits?.pendingRequestedAt;

    if (
        pendingLimitValue === null ||
        pendingLimitValue === undefined ||
        pendingRequestedAt === null ||
        pendingRequestedAt === undefined
    ) {
        return null;
    }

    const message = pendingMessage(configComponents, usersState, pendingLimitValue, pendingRequestedAt);

    return (
        <Messages
            type='success'
            message={message}
            dataTest='limit-set-message'
        />
    );
};
const renderLabel = (
    languagesState: LanguagesState,
    depositLimitTime: 'daily' | 'weekly' | 'monthly',
    depositLimitsType: ConfigComponents['config']['depositLimitsType']
): string => {
    if (depositLimitsType === 'calendarType') {
        if (depositLimitTime === 'daily') {
            return languagesState.getTranslation('account.net-deposit-limit.label.daily', 'Set New Daily Limit');
        } else if (depositLimitTime === 'weekly') {
            return languagesState.getTranslation('account.net-deposit-limit.label.weekly', 'Set New Weekly Limit');
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        } else if (depositLimitTime === 'monthly') {
            return languagesState.getTranslation('account.net-deposit-limit.label.monthly', 'Set New Monthly Limit');
        }
    } else {
        if (depositLimitTime === 'daily') {
            return languagesState.getTranslation('account.net-deposit-limit.label.oneDay', '1 Day');
        } else if (depositLimitTime === 'weekly') {
            return languagesState.getTranslation('account.net-deposit-limit.label.sevenDays', '7 Days');
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        } else if (depositLimitTime === 'monthly') {
            return languagesState.getTranslation('account.net-deposit-limit.label.thirtyDays', '30 Days');
        }
    }

    return assertNever('renderLabelTopUpLimitItem', depositLimitTime);
};

interface PropsType {
    state: RollingNetDepositLimitItemState;
    version?: 'beta' | 'default';
    depositLimitTime: 'daily' | 'weekly' | 'monthly';
}

export const TopUpLimitsProcedureItem = observer('TopUpLimitsItem', (props: PropsType) => {
    const { state, version = 'default', depositLimitTime } = props;
    const common = useCommon();
    const configComponents = ConfigComponents.get(common);
    const languagesState = LanguagesState.get(common);

    const usersState = state.usersState;

    return (
        <>
            <TopUpLimitsInput
                type='text'
                maxLength={10}
                label={renderLabel(languagesState, depositLimitTime, configComponents.config.depositLimitsType)}
                currency={BasicDataModel.get(common).moneySymbol}
                placeholder={state.activeLimit}
                state={state.limitAmount}
                inputRef={state.setAmountInputRef}
                onChange={state.onChangeInput}
                colorTheme='light'
                onBlur={state.onBlur}
            />
            {renderPending(configComponents, usersState, state.getLimits())}
            {state.errorMaxLimitExceeded ? (
                <Messages
                    type='error'
                    message={
                        <I18n
                            langKey='account.rolling-net-deposit-limits.max-limit-input'
                            defaultText='Please set limit below {maxLimitValue}'
                            params={{
                                maxLimitValue: configComponents.precision
                                    .newFromAnything(state.getLimits()?.limitValueMax ?? 0)
                                    .format(usersState.currency),
                            }}
                        />
                    }
                    dataTest='limit-set-message'
                />
            ) : null}

            <TPWrapper version={version}>
                {configComponents.config.depositLimitsType === 'calendarType' ? (
                    <AmountInputDescription data-test='limit-amount-used-so-far'>
                        <div>
                            <span>
                                <I18n
                                    langKey='account.net-deposit-limits.used-so-far.label'
                                    defaultText='Used so far:'
                                />
                                {` ${state.amountUsedCurrency}`}
                            </span>
                        </div>
                        <div>
                            <span>
                                <I18n
                                    langKey='account.net-deposit-limits.resets-on.label'
                                    defaultText='Resets on '
                                />
                                {state.getFormattedDate(depositLimitTime)}
                            </span>
                        </div>
                    </AmountInputDescription>
                ) : (
                    <AmountInputDescription data-test='limit-amount-remaining'>
                        <span>
                            <I18n
                                langKey='account.net-deposit-limits.remaining.label'
                                defaultText='Amount remaining: '
                            />
                        </span>
                        <span>{`${state.amountRemaining}`}</span>
                    </AmountInputDescription>
                )}
            </TPWrapper>
        </>
    );
});
