import { z } from 'zod';

export const ContactPreferenceEnum = {
    EMAIL: 'email',
    SMS: 'sms',
    PHONE: 'phone',
    WHATSAPP: 'whatsApp',
} as const;

export const InputSchema = z.object({
    username: z.string(), //Temporary
    surname: z.string(), //Temporary
    firstName: z.string(), //Temporary
    email: z.string(),
    password: z.string(),
    birthDate: z.string(),
    marketing: z.boolean(),
    contactPreferences: z.array(z.nativeEnum(ContactPreferenceEnum)), //Array<ContactPreference>;
});

export type CreateAccountInputType = z.infer<typeof InputSchema>;

export const SuccessResponseSchema = z.object({
    token: z.string(),
});

type TsuccessResponseType = z.infer<typeof SuccessResponseSchema>;

export const ErrorResponseSchema = z.object({
    code: z.string().optional().nullable(),
    debugDetails: z.string().optional().nullable(),
    details: z.string().optional().nullable(),
    errors: z.array(
        z.object({
            code: z.string().optional().nullable(),
            debugDetails: z.string().optional().nullable(),
            field: z.string().optional().nullable(),
            pointer: z.string().optional().nullable(),
            resource: z.string().optional().nullable(),
        })
    ),
    message: z.string().optional().nullable(),
});

type TerrorResponseType = z.infer<typeof ErrorResponseSchema>;

export type TresponseType =
    | { responseStatus: 'success'; data: TsuccessResponseType }
    | { responseStatus: 'error'; data: TerrorResponseType };
