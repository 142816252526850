import React from 'react';
import { observer } from 'src/utils/mobx-react';
import {
    Header,
    DateOfBirth,
    PhoneNumber,
    PromoCode,
    AlreadyHaveAccount,
    SignupType,
} from 'src/domains/players/webview/components/SignUp';
import { SignUpSecondaryCreateAccountState } from 'src/domains/players/webview/components/SignUp/signupSecondary/signUpSecondaryState/SignUpSecondaryCreateAccount.state';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import {
    SignUpSecondaryForm,
    SubmitButton,
} from 'src/domains/players/webview/components/SignUp/signupSecondary/signUpSecondaryViews/createAccount/CreateAccount.style';
import { SignUpSecondaryLayout } from 'src/domains/players/webview/components/SignUp/signupSecondary/SignUpSecondaryLayout';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';

interface CreateAccountPropsType {
    state: SignUpSecondaryCreateAccountState;
    signUpState: SignupType;
}

export const CreateAccount = observer(
    'SignUpSecondaryCreateAccount',
    ({ state, signUpState }: CreateAccountPropsType) => {
        const { appLayoutsState } = useAppStateContext();
        const { configComponents, languagesState } = appLayoutsState;
        const { config } = configComponents;

        return (
            <SignUpSecondaryLayout signUpState={signUpState.state}>
                <Header />
                <SignUpSecondaryForm>
                    <Input
                        label={
                            <I18n
                                langKey='signup.welcome.email.label'
                                defaultText='Email'
                            />
                        }
                        state={state.emailState}
                        placeholder={languagesState.getTranslation(
                            'signup.welcome.email.placeholder',
                            'Enter a valid email'
                        )}
                        showSuccessIcon={state.isCorrectEmail}
                        dataTest='email-input'
                    />
                    <Input
                        label={
                            <I18n
                                langKey='signup.welcome.password.label'
                                defaultText='Create Password'
                            />
                        }
                        state={state.passwordState}
                        isShowPassword={state.isShowPassword}
                        showPassword={state.showPassword}
                        showPasswordFlag={true}
                        type={state.isShowPassword ? 'text' : 'password'}
                        placeholder={languagesState.getTranslation(
                            'signup.welcome.password.placeholder',
                            'Enter your unique password'
                        )}
                        showSuccessIcon={state.isCorrectPassword}
                        dataTest='create-password-input'
                    />

                    <DateOfBirth dateState={state.dateOfBirth} />

                    <PhoneNumber
                        state={state.phoneNumber}
                        hasPrefixes={config.prefixMultiPhoneNumber}
                        placeholder='0000 000000'
                        onBlur={config.zipCodeAndCountryCheck === true ? state.handleWasTouched : undefined}
                    />

                    {config.signUpShowPromoCode ? (
                        <PromoCode
                            isPromoCode={state.isPromoCode}
                            setPromoCode={state.setPromoCode}
                            state={state}
                        />
                    ) : null}

                    <SubmitButton
                        type='submit'
                        size='large'
                        onClick={state.createAccount}
                        isLoading={state.isLoading}
                        dataTest='create-account-button'
                    >
                        <I18n
                            langKey='signup.welcome.next-step'
                            defaultText='Create Account'
                        />
                    </SubmitButton>
                    {state.backendErrorMessage === null ? null : (
                        <Messages
                            type='error'
                            marginBottom='16px'
                            message={state.backendErrorMessage}
                        />
                    )}

                    <AlreadyHaveAccount />
                </SignUpSecondaryForm>
            </SignUpSecondaryLayout>
        );
    }
);
