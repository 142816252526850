import { action, computed, makeObservable } from 'mobx';
import { EnvironmentState } from './environmentState/EnvironmentState';
import { StarRouter } from './router/StarRouter';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { FeatureState } from 'src/domains/layouts/config/features/featureState/FeatureState';
import { GoogleOptimizeState } from './googleState/GoogleOptimizeState';
import { GoogleTagManagerState } from './googleState/GoogleTagManagerState';
import { BannersBoxState } from './bannersBoxState/BannersBoxState';
import { HeroWidgetState } from './heroWidgetState/HeroWidgetState';
import { NetworkConnectionState } from './networkConnectionState/NetworkConnectionState';
import { DebugPanelState } from 'src/domains/layouts/webview/components/debugPanel/DebugPanelState';
import { Session } from 'src_common/sdk/session';
import { PopupState } from './popupState/PopupState';
import { BreakpointsState } from './breakpointsState/BreakpointsState';
import { SdkCustomer } from 'src/domains/layouts/state/customer';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { PopularSportState } from 'src/domains/layouts/state/popularSportState/PopularSportState';
import { NebetDefaultCompetitionState } from 'src/domains/layouts/state/nebetDefaultCompetitionState/NebetDefaultCompetitionState';
import { OverlayLuckyKing } from 'src/domains/layouts/state/overlay/OverlayLuckyKing';
import { IsStaticLuckyKingState } from 'src/domains/layouts/state/isStaticState/IsStaticLuckyKingState';
import { NotificationsState } from 'src/domains/layouts/state/notificationsState/NotificationsState';
import { SportsList } from 'src/domains/layouts/state/sportsList/SportsList';
import { NavigationFavourites } from 'src/domains/layouts/state/navigationFavourites/NavigationFavourites';
import { RecentlyVisitedLocalStorage } from 'src/domains/layouts/state/localStorage/RecentlyVisitedLocalStorage';
import { EventsCollectionList, StreamViewDetails } from 'src/domains/sportsbook/shared/Types';
import { SportModel } from 'src_common/common/websocket2/models/SportModel/SportModel';
import { LifeSpanState } from 'src/domains/layouts/state/lifespanState/LifespanState';
import { SelectionModel } from 'src_common/common/websocket2/models/SelectionModel/SelectionModel';
import { Overlay } from 'src/domains/layouts/state/overlay/Overlay';
import { IsStaticState } from 'src/domains/layouts/state/isStaticState/IsStaticState';
import { UserAttributionState } from './userAttributionState/userAttributionState';
import { CurrencyType } from 'src_common/common/amount/website-money/currency';
import { MarketingNotificationsState, ResourceCustomer } from 'src/domains/players/shared/Types';
import { SearchState } from 'src/domains/layouts/webview/components/search/Search.state';
import { GameSharedModel } from 'src/domains/casino/shared/Types';
import { WalletDataTypeAmountType } from 'src/domains/players/state/UsersState';
import { EventsCollectionQueryFilterMarketType } from 'src_common/common/websocket2/modelsApi/EventsCollectionQuery';
import { PromotedEventsState } from 'src/domains/layouts/webview/components/promotedSports/PromotedEventsState.state';
import { RealityCheckPopupState } from 'src/domains/layouts/state/popups/RealityCheckPopupState';
import { assertNever } from 'src_common/common/assertNever';
import { OpenapiProxyCustomerRealityCheckResponse200Type } from 'src/api_openapi/generated/openapi_proxy_customer_reality_check';
import {
    OpenapiProxyCustomerSetRealityCheckParamsType,
    OpenapiProxyCustomerSetRealityCheckResponse200Type,
} from 'src/api_openapi/generated/openapi_proxy_customer_set_reality_check';
import { CompetitionId } from 'src_common/common/websocket2/id/WebsocketId';
import { GeolocalizationState } from './geolocalizationState/GeolocalizationState';
import { MarketingNotificationsPopup } from './popupState/popupTypes';
import { FormatType, ImageOptimizeOptions, imageOptimizedUrl } from 'src_common/common/imageOptimization';
import { ActiveSpecialSportsType } from 'src/domains/sportsbook/state/specialSportsState/SpecialSportsState';
import { LocalStorageState } from './localStorage/LocalStorageState';
import { Common } from 'src/domains/common/Common';

export interface LayoutsCallbacksType {
    getUserId: () => string | null;
    getActiveSpecialSportsForView: () => Array<ActiveSpecialSportsType>;
    getSportsAndEventsListAllowed: () => Map<string, number>;
    listOfSport: (sportId: string) => EventsCollectionList;
    getGameById: (gameId: number) => GameSharedModel | null;
    isGameExists: (gameId: number) => boolean;
    getSport: (sportId: string) => SportModel | null;
    getSelection: (selectionId: number) => SelectionModel | null;
    getIsCasinoMiniMobileOverlayOpen: () => boolean;
    getSpecialEventsLandingPageShouldDisplay: () => boolean;
    getSearchStateIsShow: () => boolean;
    getCurrency: () => CurrencyType;
    getWalletData: () => ResourceCustomer<WalletDataTypeAmountType>;
    getStreamViewDetails: () => StreamViewDetails;
    onRoutingAccountChange: (route: string) => void;
    getListOfSportAndCompetition: (sport: string, competition: CompetitionId) => EventsCollectionList;
    getListOfSport: (sport: string, filterMarket?: EventsCollectionQueryFilterMarketType) => EventsCollectionList;
    getAllSportsLength: () => number;
    getRealityCheckFrequency: () => OpenapiProxyCustomerRealityCheckResponse200Type | null;
    handleSetRealityCheckFrequency: (
        params: OpenapiProxyCustomerSetRealityCheckParamsType
    ) => Promise<OpenapiProxyCustomerSetRealityCheckResponse200Type>;
    realityCheckPopupForCasino: () => boolean;
}

export class AppLayoutsState {
    /**
     * @deprecated - please use Common.session
     */
    public readonly session: Session;
    /**
     * @deprecated - please use StarRouter.get(common)
     */
    public readonly starRouter: StarRouter;
    /**
     * @deprecated - please use EnvironmentState.get(common)
     */
    public readonly env: EnvironmentState;
    /**
     * @deprecated - please use ConfigComponents.get(common)
     */
    public readonly configComponents: ConfigComponents;
    /**
     * @deprecated - please use FeatureState.get(common)
     */
    public readonly featureState: FeatureState;
    /**
     * @deprecated - please use GoogleOptimizeState.get(common)
     */
    public readonly googleOptimize: GoogleOptimizeState;
    /**
     * @deprecated - please use GoogleTagManagerState.get(common)
     */
    public readonly googleTagManager: GoogleTagManagerState;
    public readonly bannersBoxState: BannersBoxState;
    public readonly heroWidgetState: HeroWidgetState;
    public readonly networkConnectionState: NetworkConnectionState;
    /**
     * @deprecated - please use BreakpointsState.get(common)
     */
    public readonly breakpointsState: BreakpointsState;
    /**
     * @deprecated - please use DebugPanelState.get(common);
     */
    public readonly debugPanel: DebugPanelState; // move local ( refactor needed )
    public readonly popupState: PopupState;
    /**
     * @deprecated - please use SdkCustomer.get(common)
     */
    public readonly sdkCustomer: SdkCustomer;

    /**
     * @deprecated - please use LanguagesState.get(common)
     */
    public readonly languagesState: LanguagesState;
    /**
     * @deprecated - please use PopularSportState.get(common)
     */
    public readonly popularSportState: PopularSportState;
    public readonly defaultCompetitionState: NebetDefaultCompetitionState;
    public readonly overlay: Overlay;
    public readonly isStatic: IsStaticState;
    public readonly overlayLuckyKing: OverlayLuckyKing;
    public readonly isStaticLuckyKing: IsStaticLuckyKingState;
    public readonly notificationsState: NotificationsState;
    public readonly sportsList: SportsList;
    public readonly navigationFavourites: NavigationFavourites;
    public readonly recentlyVisitedLocalStorage: RecentlyVisitedLocalStorage;
    public readonly lifeSpanState: LifeSpanState;
    /**
     * @deprecated - please use UserAttributionState.get(common)
     */
    public readonly userAttributionState: UserAttributionState;
    public readonly callbacks: LayoutsCallbacksType;
    public readonly searchState: SearchState;
    public readonly promotedEventsState: PromotedEventsState;
    public readonly getStreamViewDetails: () => StreamViewDetails;
    public readonly getListOfSportAndCompetition: (sport: string, competition: CompetitionId) => EventsCollectionList;
    public readonly getListOfSport: (
        sport: string,
        filterMarket?: EventsCollectionQueryFilterMarketType
    ) => EventsCollectionList;
    public readonly getAllSportsLength: () => number;
    public readonly realityCheckPopupState: RealityCheckPopupState;
    /**
     * @deprecated - please use GeolocalizationState.get(common)
     */
    public readonly geolocalization: GeolocalizationState;

    public constructor(
        common: Common,
        callbacks: LayoutsCallbacksType,
        marketingNotificationsState: () => MarketingNotificationsState
    ) {
        makeObservable(this);
        const localStorageState = LocalStorageState.get(common);
        this.callbacks = callbacks;
        this.session = common.session;
        this.userAttributionState = UserAttributionState.get(common);
        this.env = EnvironmentState.get(common);

        this.configComponents = ConfigComponents.get(common);
        this.geolocalization = GeolocalizationState.get(common);
        this.sdkCustomer = SdkCustomer.get(common);
        this.starRouter = StarRouter.get(common);
        this.featureState = FeatureState.get(common);
        this.googleOptimize = GoogleOptimizeState.get(common);
        this.googleTagManager = GoogleTagManagerState.get(common);
        this.bannersBoxState = new BannersBoxState();
        this.heroWidgetState = new HeroWidgetState(this.sdkCustomer.models);
        this.networkConnectionState = new NetworkConnectionState(this.configComponents);
        this.breakpointsState = BreakpointsState.get(common);
        this.debugPanel = DebugPanelState.get(common);

        this.realityCheckPopupState = new RealityCheckPopupState(
            this.configComponents,
            this.starRouter,
            localStorageState,
            this.callbacks,
            common
        );

        this.popupState = new PopupState(this.configComponents, () => {
            if (this.realityCheckPopupState.isPopupShouldAppear) {
                return {
                    type: 'popupRealityCheck',
                };
            }

            if (this.realityCheckPopupState.isPopupRealityCheckNewValueConfirmationShouldAppear) {
                return {
                    type: 'popupRealityCheckNewValueConfirmation',
                };
            }

            const { isModalAvailable } = marketingNotificationsState();
            if (isModalAvailable) {
                return new MarketingNotificationsPopup();
            }

            return null;
        });

        this.languagesState = LanguagesState.get(common);
        this.popularSportState = PopularSportState.get(common);
        this.defaultCompetitionState = new NebetDefaultCompetitionState();
        this.overlayLuckyKing = new OverlayLuckyKing(this.starRouter);
        this.isStaticLuckyKing = new IsStaticLuckyKingState(
            this.starRouter,
            this.overlayLuckyKing,
            this.sdkCustomer.session,
            this.popupState,
            {
                getSearchStateIsShow: callbacks.getSearchStateIsShow,
            }
        );
        this.notificationsState = new NotificationsState(
            localStorageState,
            this.starRouter,
            () => callbacks.getUserId(),
            this.sdkCustomer,
            callbacks.isGameExists
        );

        this.sportsList = new SportsList(common, {
            getActiveSpecialSportsForView: callbacks.getActiveSpecialSportsForView,
            getSportsAndEventsListAllowed: callbacks.getSportsAndEventsListAllowed,
        });
        this.navigationFavourites = new NavigationFavourites(this.sportsList);
        this.recentlyVisitedLocalStorage = new RecentlyVisitedLocalStorage(common, {
            getGameById: callbacks.getGameById,
            getSport: callbacks.getSport,
            getSportsAndEventsListAllowed: callbacks.getSportsAndEventsListAllowed,
            listOfSport: callbacks.listOfSport,
        });

        this.lifeSpanState = new LifeSpanState(
            this.session,
            this.configComponents,
            {
                getSelection: callbacks.getSelection,
                getCurrency: callbacks.getCurrency,
            },
            this.googleTagManager
        );

        this.overlay = new Overlay(this.starRouter, this.popupState, this.callbacks.getIsCasinoMiniMobileOverlayOpen);

        this.isStatic = new IsStaticState(
            this.configComponents,
            this.starRouter,
            this.overlay,
            this.sdkCustomer.session,
            this.popupState,
            this.networkConnectionState,
            {
                getSpecialEventsLandingPageShouldDisplay: callbacks.getSpecialEventsLandingPageShouldDisplay,
                getSearchStateIsShow: callbacks.getSearchStateIsShow,
            }
        );

        this.searchState = new SearchState(common, this.configComponents, this.starRouter, this.overlay);

        this.promotedEventsState = new PromotedEventsState(this.sdkCustomer);

        this.getStreamViewDetails = callbacks.getStreamViewDetails;

        this.getListOfSportAndCompetition = callbacks.getListOfSportAndCompetition;
        this.getListOfSport = callbacks.getListOfSport;
        this.getAllSportsLength = callbacks.getAllSportsLength;

        this.starRouter.onChangeCurrentView((prevCurrentView, nextCurrentView) => {
            const prevView = prevCurrentView ?? null;
            const nextView = nextCurrentView ?? null;

            if (prevView !== null && nextView !== null && prevView !== nextView) {
                this.notificationsState.onHideAll();
            }
        });

        this.starRouter.onChangeCurrentView((prevCurrentView, nextCurrentView) => {
            if (prevCurrentView !== null && nextCurrentView !== null && prevCurrentView !== nextCurrentView) {
                this.recentlyVisitedLocalStorage.setItem(nextCurrentView);
            }
        });
    }

    @action public walletResource = (): ResourceCustomer<WalletDataTypeAmountType> => {
        return this.callbacks.getWalletData();
    };

    @computed public get isStaticForView(): boolean {
        //Layouts State //like IsStaticLuckyKingState
        const { layout } = this.configComponents.config;
        switch (layout) {
            case 'primary': {
                return this.isStatic.isStatic;
            }
            case 'secondary': {
                return this.isStaticLuckyKing.isStatic;
            }
            default: {
                return assertNever('layout', layout);
            }
        }
    }

    public optimizeImage = (
        imgSrc: string,
        format: FormatType,
        optimizeOptions?: ImageOptimizeOptions
    ): string | null => {
        const params = {
            imgSrc,
            format,
            ...optimizeOptions,
            quality: optimizeOptions?.quality ?? 85,
        };

        if (this.configComponents.config.imageOptimization === null) {
            return null;
        }

        if (this.configComponents.config.imageOptimization.type === 'baseUrl') {
            return imageOptimizedUrl(params, this.configComponents.config.imageOptimization.url);
        }
        return imageOptimizedUrl(params, null);
    };
}
