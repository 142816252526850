import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { SignUpSecondaryState } from 'src/domains/players/webview/components/SignUp/signupSecondary/signUpSecondaryState/SignUpSecondary.state';
import { CreateAccount } from 'src/domains/players/webview/components/SignUp/signupSecondary/signUpSecondaryViews/createAccount/CreateAccount';
import { useAppStateContext } from 'src/appState/AppState';
import { SignupType } from 'src/domains/players/webview/components/SignUp';
import { LuckyKingAccountTabWrapper } from 'src/domains/players/webview/components/SignUp/signupSecondary/SIgnUpSecondaryContainer.style';
import { useCommon } from 'src/domains/common/Common';

interface SignUpSecondaryContainerPropsType {
    signUpState: SignupType;
}

const SignUpSecondaryContainer = observer(
    'SignUpSecondaryContainer',
    ({ signUpState }: SignUpSecondaryContainerPropsType) => {
        const { appLayoutsState, appPlayersState } = useAppStateContext();
        const { languagesState, configComponents, starRouter } = appLayoutsState;
        const { config } = configComponents;
        const { customKeyboard } = appPlayersState;
        const common = useCommon();

        const [state] = useState(
            () =>
                new SignUpSecondaryState(
                    signUpState.state,
                    customKeyboard,
                    languagesState,
                    config,
                    starRouter,
                    common.trpcClient
                )
        );

        switch (state.step.type) {
            case 'create-account':
                return (
                    <CreateAccount
                        state={state.step.state}
                        signUpState={signUpState}
                    />
                );
            case 'deposit':
                return <div>Deposit</div>;
            default:
                return null;
        }
    }
);

export const SignUpSecondaryContainerWrapper = observer(
    'SignUpSecondaryContainerWrapper',
    ({ signUpState }: SignUpSecondaryContainerPropsType) => {
        return (
            <LuckyKingAccountTabWrapper>
                <SignUpSecondaryContainer signUpState={signUpState} />
            </LuckyKingAccountTabWrapper>
        );
    }
);
